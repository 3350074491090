import clsx from "clsx";
import { motion } from "framer-motion";
import { PropsWithChildren } from "react";
import { useFeatureContext } from "./FeatureContext";

export type FeatureCardProps = {
  gradient?: string | undefined;
  index: number;
} & CardProps;

export const FeatureCard = ({
  children,
  gradient,
  id,
  index,
}: PropsWithChildren<FeatureCardProps>) => {
  const context = useFeatureContext();
  return (
    <>
      <motion.div
        className={clsx(
          "transition-gradient absolute inset-0 h-full w-full rounded-2xl bg-gradient-to-br",

          `${gradient}`,
        )}
        initial={{ opacity: 0 }}
        animate={{
          opacity: context.inViewFeature === id ? 1 : 0,
        }}
      >
        <div className="absolute inset-0 flex items-center justify-center p-2">
          {children}
        </div>
      </motion.div>
    </>
  );
};

export type CardProps = {
  id: string;
};

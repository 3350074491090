import clsx from "clsx";
import { motion, useInView } from "framer-motion";
import { PropsWithChildren, useEffect, useRef } from "react";
import { useFeatureContext } from "./FeatureContext";

export type FeatureProps = {
  id: string;
  content: JSX.Element;
};

export const Feature = ({ id, content }: PropsWithChildren<FeatureProps>) => {
  const ref = useRef<HTMLParagraphElement>(null);
  const context = useFeatureContext();
  const isInView = useInView(ref, {
    margin: "-50% 0px -50% 0px",
  });

  useEffect(() => {
    if (isInView && context.inViewFeature !== id) {
      context.setInViewFeature?.(id);
    } else if (!isInView && context.inViewFeature === id) {
      // context.setInViewFeature?.(null); // dont hide feature

      const windowWidth = window.innerWidth;
      if (windowWidth < 768) {
        context.setInViewFeature?.(null);
      }
    }
  }, [isInView, context.inViewFeature, context, id]);

  return (
    <motion.div
      ref={ref}
      className={clsx(
        "py-16 pr-2 transition-colors",
        isInView ? "opacity-100" : "opacity-50",
      )}
      animate={{ opacity: isInView ? 1 : 0.5 }}
      transition={{ duration: 0.1 }}
    >
      {content}
    </motion.div>
  );
};

import { PropsWithoutRef } from "react";
import { FeaturesSection } from "./FeaturesSection";
import { Stats } from "./Stats";

import {
  ArrayElement,
  FeaturesContent as baseFeaturesText,
  HomeContent as baseHomePageText,
} from "@onzeit/shared";

// @ts-ignore
import { Link } from "@remix-run/react";
import { LearnMoreArrow } from "../Layout/LearnMoreArrow";
import { Hero } from "./Hero";

export const Homepage = (
  props: PropsWithoutRef<{
    homepageText: ArrayElement<typeof baseHomePageText>;
    featuresText: ArrayElement<typeof baseFeaturesText>;
  }>,
) => {
  let homepageText = props.homepageText ?? baseHomePageText[0];
  let featuresText = props.featuresText ?? baseFeaturesText[0];

  return (
    <>
      {/* <TailwindBreakpointsIndicator /> */}
      <Hero text={homepageText} />
      <LearnMoreArrow />
      <div className="mx-auto mt-12 max-w-6xl">
        <FeaturesSection
          homePageText={homepageText}
          featureText={featuresText}
          locale="de"
        />

        <h2 className="mb-4 mt-20 px-4 text-2xl font-bold text-indigo-700 sm:text-3xl lg:mt-40">
          {homepageText.data.stats_headline_1}
        </h2>

        <p className="font-regular px-4 text-xl">
          {homepageText.data.stats_headline_1_subtext}
        </p>

        <h2 className="mb-4 mt-20 px-4 text-2xl font-bold text-indigo-700 sm:text-3xl">
          {homepageText.data.stats_headline_2}
        </h2>

        <Stats stats={homepageText.data.stats || []} />

        <div className="mb-8 mt-12 px-4 text-center">
          <div className="rounded-2xl bg-indigo-700">
            <div className="py-16 sm:px-6 sm:py-24 lg:px-8">
              <div className="mx-auto max-w-2xl text-center">
                <h2 className="text-balance text-3xl font-bold tracking-tight text-white sm:text-4xl">
                  {props.homepageText.data.cta_title}
                </h2>
                <p className="text-balance mt-6 text-lg leading-8 text-indigo-200">
                  {props.homepageText.data.cta_subtext}
                </p>
                <div className="mt-10 flex flex-col items-center justify-center gap-2 gap-y-4 sm:flex-row sm:gap-y-0 lg:gap-x-6">
                  <Link
                    to={props.homepageText.data.cta.url}
                    className="font-sm text-md rounded-md bg-white px-3.5 py-2.5 font-semibold text-indigo-600 shadow-sm hover:bg-indigo-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                  >
                    {props.homepageText.data.cta.title}
                  </Link>
                  <Link
                    to={props.homepageText.data.cta_discover_more_link!}
                    className="text-md font-semibold leading-6 text-white"
                  >
                    {props.homepageText.data.discover_more}{" "}
                    <span aria-hidden="true">→</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

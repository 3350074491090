import { motion } from "framer-motion";
import { StatFact } from "./StatFact";

export const Stats = ({
  stats,
}: React.PropsWithoutRef<{
  stats: Array<{
    text: string;
    percent: string;
  }>;
}>) => {
  return (
    <motion.div className="ml-2 mt-8 grid grid-cols-[72px_1fr] items-start justify-start gap-x-2 gap-y-4 lg:grid-cols-[96px_1fr] lg:gap-y-8">
      {stats.map((stat, id) => (
        <StatFact
          key={id}
          text={stat.text}
          percent={parseInt(stat.percent)}
          pathColor="#10B981"
          textColor="#10B981"
          trailColor="#D1FAE5"
        />
      ))}
    </motion.div>
  );
};

import { PropsWithoutRef } from "react";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";

export const StatFact = ({
  text,
  percent,
  pathColor,
  textColor,
  trailColor,
}: PropsWithoutRef<{
  text: string;
  percent: number;
  pathColor: string;
  textColor: string;
  trailColor: string;
}>) => {
  return (
    <>
      <div>
        <CircularProgressbarWithChildren
          strokeWidth={16}
          value={percent}
          styles={buildStyles({
            pathColor,
            textColor,
            trailColor,
          })}
        >
          <p
            style={{
              color: textColor,
            }}
          >
            {percent + "%"}
          </p>
        </CircularProgressbarWithChildren>
      </div>

      <blockquote className="font-regular ml-4 mr-4 text-lg italic lg:mr-0 lg:w-3/4 lg:text-2xl">
        {`„${text}“`}
      </blockquote>
    </>
  );
};

import { motion } from "framer-motion";
import { PropsWithoutRef } from "react";

import { Container } from "../../Container";
import { Image } from "../../Image";

import {
  ArrayElement,
  CLOUDINARY_ASSETS,
  HomeContent as baseHomePageText,
} from "@onzeit/shared";
import Link from "~/components/Link";

export function Hero(
  props: PropsWithoutRef<{
    text?: ArrayElement<typeof baseHomePageText>;
  }>,
) {
  const text = props.text ?? baseHomePageText[0];

  return (
    <Container className="mt-8 rounded-xl bg-gradient-to-br from-indigo-400/20 via-pink-200/20 to-yellow-400/20 drop-shadow-xl sm:mt-12 sm:rounded-3xl">
      <Container className="py-8 sm:py-24">
        <div className="text-center lg:flex lg:items-center lg:text-left">
          <div className="max-w-[95%] lg:max-w-[60%] lg:pl-8">
            {/* <h1 className="max-w-5xl mx-auto text-2xl font-bold tracking-tight text-slate-900 sm:text-4xl lg:text-5xl">
            {text.data.hero_title_1}{" "}
            <span className="relative text-indigo-600 whitespace-nowrap">
              <svg
                aria-hidden="true"
                viewBox="0 0 418 42"
                className="absolute left-0 top-2/3 h-[0.58em] w-full fill-indigo-300/70"
                preserveAspectRatio="none"
              >
                <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z" />
              </svg>
              <span className="relative">{text.data.hero_title_2}</span>
            </span>
            <div className="relative">{text.data.hero_title_3}</div>
          </h1> */}

            <h1 className="animate-gradient bg-300% xs:text-4xl  mx-auto max-w-2xl bg-gradient-to-br from-indigo-600 via-violet-600 to-rose-700 bg-clip-text text-3xl font-bold leading-8 tracking-tight text-transparent sm:text-4xl lg:mx-0 lg:text-5xl ">
              {text.data.hero_title_1} {text.data.hero_title_2}{" "}
              {text.data.hero_title_3}
            </h1>

            <p className="font-regular text-balance mx-auto mt-6 text-lg tracking-tight text-slate-700 lg:text-2xl xl:pr-8">
              {text.data.hero_subtext}
            </p>
            <div className="mt-4 flex justify-center sm:mt-10 lg:justify-start">
              <div className="mt-10 flex flex-col items-center justify-center gap-x-6 gap-y-4 sm:flex-row sm:gap-y-0 sm:text-lg">
                <Link
                  className="rounded-md bg-indigo-700 px-4 py-2 text-white hover:bg-indigo-800"
                  to={text.data.cta.url}
                >
                  {text.data.cta.title}
                </Link>
                <Link
                  to={text.data.cta_discover_more_link}
                  className="font-semibold leading-6 text-indigo-600"
                >
                  {text.data.discover_more} <span aria-hidden="true">→</span>
                </Link>
              </div>

              {/* <Button
          href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
          variant="outline"
        >
          <svg
            aria-hidden="true"
            className="flex-none w-3 h-3 fill-indigo-600 group-active:fill-current"
          >
            <path d="m9.997 6.91-7.583 3.447A1 1 0 0 1 1 9.447V2.553a1 1 0 0 1 1.414-.91L9.997 5.09c.782.355.782 1.465 0 1.82Z" />
          </svg>
          <span className="ml-3">Watch video</span>
        </Button> */}
            </div>
          </div>

          <div className="hidden lg:block">
            <motion.div className="h-48 w-48 overflow-hidden rounded-3xl bg-indigo-600 lg:ml-4 lg:h-96 lg:w-96">
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                className="relative -ml-36 mt-8 h-48 w-48 rounded-full bg-yellow-500"
              >
                &nbsp;
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    type: "spring",
                    bounce: 0.7,
                    duration: 0.5,
                  },
                }}
                className="relative mt-24 h-48 w-48 rounded-full bg-pink-500"
              >
                &nbsp;
              </motion.div>
            </motion.div>
            <motion.div
              className="mt-[-150px] h-[150px] w-[150px] lg:mt-[-450px] lg:h-[450px] lg:w-[450px]"
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
            >
              <Image
                alt="header-image"
                cloudinaryId={CLOUDINARY_ASSETS.home.hero_image}
                width={450}
                height={450}
                srcSets={[{ width: 612, height: 612 }]}
                className="absolute"
              />
            </motion.div>
          </div>
        </div>
      </Container>
    </Container>
  );
}

import { ArrowDownIcon } from "@heroicons/react/24/solid";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";

export const LearnMoreArrow = () => {
  const [state, setState] = useState<{
    scrollPosition: number;
  }>({
    scrollPosition: 0,
  });

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }
    const handleScroll = () => {
      setState({
        scrollPosition: window.scrollY,
      });
    };
    window.addEventListener("scroll", handleScroll);
    setState({
      scrollPosition: window.scrollY,
    });
  }, [typeof window !== "undefined"]);
  return (
    <motion.div
      className="fixed bottom-2 left-[calc(50vw-24px)] flex h-12 w-12 animate-bounce items-center justify-center rounded-full bg-indigo-500 drop-shadow-lg"
      initial={{ opacity: 0 }}
      animate={{ opacity: state.scrollPosition < 100 ? 1 : 0 }}
    >
      <ArrowDownIcon className="h-6 w-6 text-white" />
    </motion.div>
  );
};

import { PropsWithoutRef } from "react";

import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { motion } from "framer-motion";
import { FeatureCard } from "./FeatureList/FeatureCard";

import {
  ArrayElement,
  FeaturesContent as baseFeaturesText,
  HomeContent as baseHomePageText,
} from "@onzeit/shared";
import { FeatureList } from "./FeatureList/FeatureList";

// @ts-ignore
import Link from "@onzeit/link";

import { Image } from "../../Image";

const fadeInAnimationVariants = {
  initial: {
    opacity: 0,
    y: 100,
  },
  animate: (i: number) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.1,
    },
  }),
};

export const FeaturesSection = (
  props: PropsWithoutRef<{
    homePageText: ArrayElement<typeof baseHomePageText>;
    featureText: ArrayElement<typeof baseFeaturesText>;
    locale: string;
  }>,
) => {
  const homePageText = props.homePageText ?? baseHomePageText[0];
  const featureText = props.featureText ?? baseFeaturesText[0];

  const features = featureText.data
    .features!.filter((x) => !x.isSecondary)
    .map((feature, id) => {
      return {
        id: id.toString(),
        card: () => (
          <FeatureCard
            id={id.toString()}
            index={id}
            gradient={feature.cardGradient}
          >
            <Image
              cloudinaryId={feature.image || ""}
              width={400}
              height={400}
              alt="Routenoptimierung"
              className=""
            />
          </FeatureCard>
        ),
        content: () => {
          return (
            <FeatureDescription
              title={feature.title}
              subtitle={feature.subtitle}
              description={feature.description}
              discoverMoreText={homePageText.data.discover_more}
              locale={props.locale}
              detailLink={feature.detailLink}
            />
          );
        },
      };
    });

  return (
    <div className="mx-auto px-4">
      <h2 className="text-2xl font-bold text-indigo-700 sm:text-3xl">
        {homePageText.data.features_head}
      </h2>
      <p className="font font-regular mb-10 mt-4 max-w-2xl text-lg">
        {homePageText.data.features_subtext}
      </p>
      <div className="-mt-48">
        <FeatureList features={features!} inViewFeature={"0"} />
        <div className="mb-12 mt-20 lg:-mt-20">
          <h2 className="my-8 text-2xl font-bold text-indigo-700 sm:text-3xl">
            {homePageText.data.features_2_headline}
          </h2>
          <div className="grid grid-cols-1 gap-12 lg:grid-cols-2">
            {featureText.data
              .features!.filter((x) => x.isSecondary)
              .map(({ title, subtitle, description, detailLink }, index) => {
                return (
                  <motion.div
                    key={`secondary-feature${index}`}
                    // initial="initial"
                    className=""
                    // whileInView={"animate"}
                    custom={index}
                    variants={fadeInAnimationVariants}
                  >
                    <div className="flex text-xl font-bold">
                      <CheckCircleIcon className="h-6 w-6 text-indigo-600" />
                      <p className="ml-2 text-xl font-semibold text-indigo-600">
                        {title}
                      </p>
                    </div>
                    <p className="mb-1 ml-8 text-lg font-bold text-slate-600">
                      {subtitle}
                    </p>
                    <div className="ml-[32px]">
                      <p className="text-base font-normal">{description}</p>
                      <p className="mt-2 font-bold text-indigo-700">
                        <Link
                          href={detailLink}
                          to={detailLink}
                          className="mt-2 text-lg font-bold text-indigo-700"
                        >
                          {homePageText.data.discover_more}
                          <span className="ml-2" aria-hidden="true">
                            →
                          </span>
                        </Link>
                      </p>
                    </div>
                  </motion.div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

const FeatureDescription = ({
  subtitle,
  title,
  description,
  discoverMoreText,
  locale,
  detailLink,
}: PropsWithoutRef<{
  subtitle: string;
  title: string;
  description: string;
  discoverMoreText: string;
  locale: string;
  detailLink: string;
}>) => {
  return (
    <div>
      <p className="mb-4 text-lg font-bold text-indigo-700 lg:text-xl">
        {subtitle}
      </p>
      <p className="text-balance mb-4 text-2xl font-bold sm:text-3xl lg:text-5xl">
        {title}
      </p>
      <p className="text-medium text-balance mb-4 text-xl">{description}</p>
      <Link
        href={detailLink}
        to={detailLink}
        className="mt-2 text-lg font-bold text-indigo-700"
      >
        {discoverMoreText}
        <span className="ml-2" aria-hidden="true">
          →
        </span>
      </Link>
    </div>
  );
};

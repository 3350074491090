import React, { PropsWithChildren, useEffect } from "react";

export interface FeatureContextProps {
  inViewFeature: string | null;
}

export const FeatureContext = React.createContext<
  FeatureContextProps & {
    setInViewFeature?: (feature: string | null) => void;
  }
>({
  inViewFeature: null,
});

const _FeatureProvider = FeatureContext.Provider;

export const FeatureProvider = ({
  inViewFeature,
  children,
}: PropsWithChildren<FeatureContextProps & {}>) => {
  const [state, setState] = React.useState<FeatureContextProps>({
    inViewFeature,
  });

  useEffect(() => {
    setState((prevState) => ({ ...prevState, inViewFeature }));
  }, [inViewFeature]);

  return (
    <_FeatureProvider
      value={{
        ...state,
        setInViewFeature: (feature: string | null) => {
          setState((prevState) => ({ ...prevState, inViewFeature: feature }));
        },
      }}
    >
      {children}
    </_FeatureProvider>
  );
};

export const useFeatureContext = () => {
  const context = React.useContext(FeatureContext);
  if (!context) {
    throw new Error("useFeatureContext must be used within a FeatureProvider");
  }
  return context;
};

import React, { PropsWithoutRef } from "react";
import { Feature } from "./Feature";
import { CardProps } from "./FeatureCard";
import { FeatureProvider } from "./FeatureContext";

export interface FeatureListProps {
  inViewFeature: string | null;
  features: {
    id: string;
    content: () => JSX.Element;
    card: React.FC<CardProps>;
  }[];
}

export const FeatureList = ({
  inViewFeature,
  features,
}: PropsWithoutRef<FeatureListProps>) => {
  return (
    <FeatureProvider inViewFeature={inViewFeature}>
      <div className="mx-auto pt-40 lg:pt-[1vh]">
        <div className="w-full max-w-6xl gap-20 lg:flex lg:items-start">
          <div className="w-full max-w-xl lg:pb-[40vh] lg:pt-[20vh]">
            <ul>
              {features.map((feature) => (
                <li key={feature.id} className="lg:mt-10">
                  <Feature id={feature.id} content={feature.content()} />
                </li>
              ))}
            </ul>
          </div>
          <div className="sticky bottom-0 right-0 flex w-full justify-end pb-4 lg:top-0 lg:h-screen lg:w-3/4 lg:items-center lg:pb-0">
            <div className="relative aspect-square w-60 lg:w-full">
              {features.map((feature) => {
                return <feature.card id={feature.id} key={feature.id} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </FeatureProvider>
  );
};
